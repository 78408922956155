
# ================
# Variables
# ================
cookie_valid_days = 90      # The number of days that the cookie should be active for.

cookie_name = "accept"      # The name of the cookie, as stored in the user's browser.

# ================
# Helper Functions
# ================
setCookie = (name, value, days) ->
  if days
    date = new Date()
    date.setTime date.getTime() + (days * 24 * 60 * 60 * 1000)
    expires = "; expires=" + date.toGMTString()
  else
    expires = ""
  document.cookie = name + "=" + value + expires + "; path=/"

getCookie = (name) ->
  nameEQ = name + "="
  ca = document.cookie.split(";")
  i = 0

  while i < ca.length
    c = ca[i]
    c = c.substring(1, c.length)  while c.charAt(0) is " "
    return c.substring(nameEQ.length, c.length)  if c.indexOf(nameEQ) is 0
    i++
  null

deleteCookie = (name) ->
  setCookie name, "", -1


# ================
# Cookie Bar
# ================
selector = $('#cookie-bar-info')
cookie = getCookie "accept"

selector.hide()
selector.show() if cookie is null

$("#cookie-bar-ok").on 'click', (e) ->
  e.preventDefault()
  selector.hide()
  setCookie cookie_name, 1, cookie_valid_days